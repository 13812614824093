import type { IconProps } from "~/utils/types";

export const StethoscopeIcon = ({ className }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2139_7896)">
      <path
        d="M2.79935 1.34165C2.80143 1.3783 2.81498 1.41337 2.83809 1.4419C2.8612 1.47042 2.8927 1.49096 2.92812 1.5006C2.96354 1.51024 3.0011 1.50849 3.03548 1.49561C3.06986 1.48273 3.09931 1.45936 3.11968 1.42881C3.14004 1.39826 3.15028 1.36209 3.14895 1.3254C3.14762 1.28871 3.13479 1.25337 3.11227 1.22438C3.08974 1.19539 3.05868 1.17421 3.02346 1.16385C2.98824 1.15349 2.95065 1.15447 2.91602 1.16665H2.33268C2.02326 1.16665 1.72652 1.28956 1.50772 1.50836C1.28893 1.72715 1.16602 2.0239 1.16602 2.33331V5.24998C1.16602 6.17824 1.53476 7.06848 2.19114 7.72485C2.84752 8.38123 3.73776 8.74998 4.66602 8.74998C5.59427 8.74998 6.48451 8.38123 7.14089 7.72485C7.79727 7.06848 8.16602 6.17824 8.16602 5.24998V2.33331C8.16602 2.0239 8.0431 1.72715 7.82431 1.50836C7.60551 1.28956 7.30877 1.16665 6.99935 1.16665H6.41602C6.40453 1.17814 6.39541 1.19178 6.38919 1.20679C6.38297 1.22181 6.37977 1.2379 6.37977 1.25415C6.37977 1.2704 6.38297 1.28649 6.38919 1.3015C6.39541 1.31652 6.40453 1.33016 6.41602 1.34165C6.42751 1.35314 6.44115 1.36225 6.45616 1.36847C6.47117 1.37469 6.48727 1.37789 6.50352 1.37789C6.51977 1.37789 6.53586 1.37469 6.55087 1.36847C6.56588 1.36225 6.57953 1.35314 6.59102 1.34165"
        stroke="#0072CD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 8.75V9.33333C4.66602 10.2616 5.03476 11.1518 5.69114 11.8082C6.34752 12.4646 7.23776 12.8333 8.16602 12.8333C9.09427 12.8333 9.98451 12.4646 10.6409 11.8082C11.2973 11.1518 11.666 10.2616 11.666 9.33333V7"
        stroke="#0072CD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 6.99996C12.311 6.99996 12.8333 6.47763 12.8333 5.83329C12.8333 5.18896 12.311 4.66663 11.6667 4.66663C11.0223 4.66663 10.5 5.18896 10.5 5.83329C10.5 6.47763 11.0223 6.99996 11.6667 6.99996Z"
        stroke="#0072CD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2139_7896">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
